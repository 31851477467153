<template>
  <div class="box">
    <div class="box-body">
      <form
        @submit.prevent="submit"
        v-promise-btn="{ action: 'submit' }"
        class="container"
      >
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label for="form_start_date" class="mr-4">วันเริ่มการทำแผน</label>
            <input
              class="form-control w-auto flex-fill"
              type="date"
              id="form_start_date"
              v-model="nationalPlanEdit.start_date"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label for="form_end_date" class="mr-4">วันสิ้นสุดการทำแผน</label>
            <input
              class="form-control w-auto flex-fill"
              type="date"
              id="form_end_date"
              v-model="nationalPlanEdit.end_date"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label for="form_start_year" class="mr-4"
              >ปีเริ่มต้น "ปีงบประมาณ"</label
            >
            <select
              class="form-control w-auto flex-fill"
              type="date"
              id="form_start_year"
              v-model="nationalPlanEdit.start_budget_year"
            >
              <option v-for="year in years" :value="year" :key="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label for="form_end_year" class="mr-4"
              >ปีสิ้นสุด "ปีงบประมาณ"</label
            >
            <select
              class="form-control w-auto flex-fill"
              type="date"
              id="form_end_year"
              v-model="nationalPlanEdit.end_budget_year"
            >
              <option v-for="year in years" :value="year" :key="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <label for="form_start_year" class="mr-4">คำต่อท้ายชื่อแผน</label>
            <input
              class="form-control w-auto flex-fill"
              type="text"
              id="suffix_title"
              v-model="nationalPlanEdit.suffix_title"
            />
          </div>
        </div>
        <div
          class="
            d-flex
            flex-row-reverse
            align-items-center
            justify-content-between
          "
        >
          <button class="btn btn-action" type="submit">
            {{ $t('common.save') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from '@/modules/form/form';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
import { range } from 'lodash';

export default {
  name: 'admin-national-plan-editor',
  props: {
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'create', // 'create' or 'edit'
    },
    nationalPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nationalPlanEdit: null,
    };
  },
  computed: {
    isEdit() {
      return this.mode == 'edit';
    },
    isCreate() {
      return this.mode == 'create';
    },
    nationalPlanThaiYear() {
      return (this.nationalPlan.year || new Date().getFullYear()) + 543;
    },
    years() {
      const step = 10;
      const currentYear = this.nationalPlanThaiYear;
      const start = currentYear - step;
      const end = currentYear + step;
      return range(start, end);
    },
  },
  created() {
    if (this.isEdit) {
      this.nationalPlanEdit = new Form({
        data: () => {
          return {
            start_date: '',
            end_date: '',
            start_budget_year: '',
            end_budget_year: '',
            suffix_title: '',
          };
        },
        validations: {
          start_budget_year: { required },
          end_budget_year: { required },
          start_date: { required },
          end_date: { required },
          suffix_title: {},
        },
      });
      this.setFormData(this.nationalPlan);
    } else {
      // create
      this.nationalPlanEdit = new Form({
        data: () => {
          return {
            start_date: '',
            end_date: '',
            start_budget_year: this.nationalPlanThaiYear,
            end_budget_year: this.nationalPlanThaiYear,
            suffix_title: '',
          };
        },
        validations: {
          start_budget_year: { required },
          end_budget_year: { required },
          start_date: { required },
          end_date: { required },
          suffix_title: {},
        },
      });
    }
  },
  methods: {
    setFormData(nationalPlan) {
      this.nationalPlanEdit.start_date = this.formatDate(
        nationalPlan.start_date
      );
      this.nationalPlanEdit.end_date = this.formatDate(nationalPlan.end_date);

      this.nationalPlanEdit.start_budget_year = nationalPlan.start_budget_year;
      this.nationalPlanEdit.end_budget_year = nationalPlan.end_budget_year;
      this.nationalPlanEdit.suffix_title = nationalPlan.suffix_title;
    },
    formatDate(dateInput) {
      return moment(dateInput).format('YYYY-MM-DD');
    },
    submit() {
      let url = `/api/national-plans`;
      let requestType = 'post';
      if (this.mode == 'edit') {
        url = `/api/national-plans/${this.nationalPlan.id}/admin`;
        requestType = 'patch';
      }
      return this.nationalPlanEdit
        .submit(requestType, url)
        .then((response) => {
          if (this.isCreate) {
            this.$emit('onSaved', response);
          } else {
            this.$emit('onUpdated', response);
          }
          this.$modal.hide(this.name);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
