<template>
  <section class="container-fluid mt-4 admin-dashboard">
    <div class="box">
      <div class="box-header d-flex justify-content-between bg-white">
        <h3 v-if="nationalPlan">
          แผนปีล่าสุด {{ this.nationalPlan.year | thai_year }}
        </h3>
        <h3 v-else>ยังไม่มีแผนพลังงาน</h3>
        <button
          class="btn btn-action py-1 px-4"
          @click="addNationalPlan"
          v-if="canAddNationalPlan">
          เพิ่มปีใหม่
        </button>
      </div>
      <div class="box-body d-flex flex-column p--3">
        <div class="flex align-items-center">
          เริ่ม {{ this.nationalPlan.start_date | thai_date }} ถึง
          {{ this.nationalPlan.end_date | thai_date }}
          <button
            class="btn btn-edit py-0 text-red"
            @click="editLatestNationalPlan">
            <eva-icon name="edit-outline"></eva-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="box mt-4">
      <div class="box-header d-flex justify-content-between bg-white">
        <h3 v-if="historyNationPlan">
          แผนปีที่ผ่านมา
        </h3>
        <h3 v-else>ยังไม่มีแผนพลังงาน</h3>
      </div>
      <div class="box-body d-flex flex-column p--3">
        <div class="flex align-items-center" v-for="(plan, index) in historyNationPlan" :key="index">
          <li>{{ plan.national_strategy_title }} ({{ plan.start_date | thai_date }} ถึง {{
              plan.end_date | thai_date
            }})
          </li>
        </div>
      </div>
    </div>
    <modals-container
      @onSaved="onCreatedNationalPlan"
      @onUpdated="onUpdatedNationalPlan"
    ></modals-container>
  </section>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import AdminNationalPlanEditor from '@/components/AdminNationalPlanEditor.vue';

export default {
  name: 'NationalPlanSettings',

  data() {
    return {
      ready: false,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(['latestNationalPlan', 'nationalPlanList']),
    historyNationPlan() {
      let list = this.nationalPlanList;
      return list.filter((plan) => {
        return plan.year < this.nationalPlan.year;
      });
    },
    nationalPlan() {
      return this.latestNationalPlan;
    },
    canAddNationalPlan() {
      if (this.nationalPlan) {
        const now = new Date();
        const endDate = new Date(this.nationalPlan.end_date);
        return now > endDate;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(['setNationalPlan', 'setLatestNationalPlan']),
    addNationalPlan() {
      this.$modal.show(
        AdminNationalPlanEditor,
        {
          name: 'national_plan_editor',
          mode: 'create',
          nationalPlan: {},
        },
        {
          name: 'national_plan_editor',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    editLatestNationalPlan() {
      this.$modal.show(
        AdminNationalPlanEditor,
        {
          name: 'national_plan_editor',
          mode: 'edit',
          nationalPlan: this.nationalPlan,
        },
        {
          name: 'national_plan_editor',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    onCreatedNationalPlan(nationalPlan) {
      this.setNationalPlan(nationalPlan);
      this.setLatestNationalPlan(nationalPlan);
    },
    onUpdatedNationalPlan(nationalPlan) {
      this.setNationalPlan(nationalPlan);
    },
  },
};
</script>

<style lang="scss"></style>
